/**
 * @returns true if Hire Me feature is enabled false otherwise.
 */
export function isHireMeEnabled() {
  return window?.sfdcBase?.exp?.FEATURE_HIRE_ME === "true";
}

/**
 * @returns true if Career Marketplace feature is enabled false otherwise.
 */
export function isCareerMarketplaceEnabled() {
  return window?.sfdcBase?.exp?.FEATURE_CAREER_MARKET === "true";
}

/**
 * @returns true if Accepted Answer Label feature is enabled false otherwise
 */
export function isAcceptedAnswersLabelEnabled() {
  return window?.sfdcBase?.exp?.FEATURE_ACCEPTED_ANSWERS_LABEL === "true";
}

/**
 * @returns true if we should hide the Profile Edit modal Settings banner false otherwise
 */
export function isHideProfileEditSettingsBannerEnabled() {
  return (
    window?.sfdcBase?.exp?.FEATURE_HIDE_PROFILE_EDIT_SETTINGS_BANNER === "true"
  );
}

export function areVirtualRegistrationFieldsEnabled() {
  return window?.sfdcBase?.exp?.FEATURE_VIRTUAL_REG_FIELDS === "true";
}

export function isTbmeSkillsComponentEnabled() {
  return window?.sfdcBase?.exp.FEATURE_TBME_SKILLS_COMPONENT === "true";
}
