import { LightningElement, wire } from "lwc";
import { ApolloQueryResult } from "@apollo/client";
import { QueryAdapter } from "tbme/queryAdapter";
import { QueryAdapter as TbcQueryAdapter } from "tbtbc/queryAdapter";
import { GetMvpContext } from "./gql/GetMvpContext";
import {
  GetTbcMvpContext,
  GetTbcMvpContextVariables,
} from "./gql/GetTbcMvpContext";
import { MVP_QUERY, TBC_MVP_QUERY } from "./query";

export default class extends LightningElement {
  private imageUrl: string = "";
  private imageTitle: string = "MVP";
  private isVisible: boolean = false;
  private isMvp: boolean = false;
  private variables: GetTbcMvpContextVariables = {
    userSlug: "",
    queryMvp: false,
  };

  @wire(QueryAdapter, {
    query: MVP_QUERY,
  })
  handleResult({ data, error }: ApolloQueryResult<GetMvpContext>) {
    if (error) console.error("error", error);
    if (!data) return;
    this.imageUrl = data.siteInfo.urls.mvpImage;
    if (data.chassis?.profile?.username) {
      this.variables.userSlug = data.chassis?.profile?.username;
      this.isVisible =
        data.chassis?.profile?.isPublicProfile || data.chassis?.isSelf;
      this.variables.queryMvp = true;
    }
  }

  @wire(TbcQueryAdapter, {
    query: TBC_MVP_QUERY,
    variables: "$variables",
  })
  handleResultTbc({ data, error }: ApolloQueryResult<GetTbcMvpContext>) {
    if (error) console.error("error", error);
    if (!this.variables.queryMvp) {
      return;
    }
    this.isMvp = !!data?.profileData?.isMvp;
  }
}
