import { LightningElement, wire } from "lwc";

import { LabelTranslations, MultiLabelAdapter } from "tbme/localization";
import { QueryAdapter, QueryAdapterValue } from "tbme/queryAdapter";

import {
  QueryAdapter as TBCQueryAdapter,
  QueryAdapterValue as TBCQueryAdapterValue,
} from "tbtbc/queryAdapter";

import { GetUserContext } from "./gql/GetUserContext";
import { GetFilesUrl, GetFilesUrlVariables } from "./gql/GetFilesUrl";

import { USER_CONTEXT_QUERY, TBC_USER_FILES_QUERY } from "./query";

export default class extends LightningElement {
  private isSelf: boolean = false;
  private isPublicProfile: boolean = false;
  private variables: GetFilesUrlVariables = {
    userSlug: "",
    queryFiles: false,
  };

  filesUrl: string | null = null;

  get visible(): boolean {
    return (this.isSelf || this.isPublicProfile) && this.filesUrl !== null;
  }

  @wire(MultiLabelAdapter, {
    labels: ["filesTitle", "viewFilesButton"],
  })
  labels: LabelTranslations = undefined!;

  @wire(QueryAdapter, { query: USER_CONTEXT_QUERY })
  private handleTbmeResult({ data }: QueryAdapterValue<GetUserContext>) {
    if (!data) return;

    if (data.chassis && data.chassis.profile && data.chassis.profile.username) {
      this.isSelf = data.chassis.isSelf;
      this.isPublicProfile = data.chassis.profile.isPublicProfile;
      this.variables.userSlug = data.chassis.profile.username;
      this.variables.queryFiles = true;
    }
  }

  @wire(TBCQueryAdapter, {
    query: TBC_USER_FILES_QUERY,
    variables: "$variables",
  })
  private handleTbcResult({ data, error }: TBCQueryAdapterValue<GetFilesUrl>) {
    if (!data || !this.variables.queryFiles) return;

    if (data.profileData?.communityUserUrls?.filesUrl) {
      this.filesUrl = data.profileData.communityUserUrls.filesUrl;
    }
  }
}
