import { LightningElement, api, wire, track } from "lwc";
import { Size } from "@devforce/th-components/modules/tds/button/button";
import { MultiLabelAdapter, LabelTranslations } from "tbme/localization";
import { QueryAdapter } from "tbme/queryAdapter";
import { USER_ACTIONS_QUERY, TBC_USER_ACTIONS_QUERY } from "./query";
import { ApolloQueryResult } from "@apollo/client";
import { UserActionsQuery } from "./gql/UserActionsQuery";
import {
  QueryAdapter as TBCQueryAdapter,
  QueryAdapterValue as TBCQueryAdapterValue,
} from "tbtbc/queryAdapter";
import {
  GetInboxUrl_profileData_communityUserUrls,
  GetInboxUrlVariables,
  GetInboxUrl,
} from "./gql/GetInboxUrl";

type UserActionsUrls = Omit<
  GetInboxUrl_profileData_communityUserUrls,
  "__typename"
> & { tbcHireMe: string };

const mediaQueryMobile = window.matchMedia("(max-width: 835px)");

export default class extends LightningElement {
  @api size: Size = "medium";
  @api showHireMeButton = false;
  notMobile = !mediaQueryMobile.matches;

  @wire(MultiLabelAdapter, {
    labels: ["tbcUserActions.message", "tbcUserActions.hireMe"],
  })
  labels: LabelTranslations = undefined!;

  variables: GetInboxUrlVariables = {
    userSlug: "",
    queryUserActions: false,
  };
  @track
  urls: UserActionsUrls = {
    tbcHireMe: "",
    inboxUrl: "",
  };
  visible = false;

  get showLinkButtons(): boolean {
    return !!this.urls.inboxUrl;
  }

  @wire(QueryAdapter, {
    query: USER_ACTIONS_QUERY,
  })
  handleResult({ data, error, errors }: ApolloQueryResult<UserActionsQuery>) {
    if (!data) {
      return;
    }
    if (error || errors) {
      this.visible = false;
      return;
    }
    if (data.chassis) {
      this.visible = !data.chassis?.isSelf ?? this.visible;
      if (data.chassis.profile.username) {
        this.variables.userSlug = data.chassis.profile.username;
        this.variables.queryUserActions = true;
      }
    }
  }

  @wire(TBCQueryAdapter, {
    query: TBC_USER_ACTIONS_QUERY,
    variables: "$variables",
  })
  handleTbcResult({ data, error, errors }: TBCQueryAdapterValue<GetInboxUrl>) {
    if (!data) return;
    if (error || errors) {
      this.visible = false;
      return;
    }
    if (
      this.variables.queryUserActions &&
      data.profileData?.communityUserUrls?.inboxUrl
    ) {
      this.urls.inboxUrl = data.profileData.communityUserUrls.inboxUrl;
      this.urls.tbcHireMe = `${data.profileData.communityUserUrls.inboxUrl}&source=hire_me`;
    }
  }
}
