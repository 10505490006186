import {
  parsePhoneNumberFromString,
  validatePhoneNumberLength,
  isPossiblePhoneNumber,
} from "libphonenumber-js";

export interface ProfileEditForm {
  [fieldName: string]: {
    value?: string | null;
    errorMessage?: string;
    // validationRules are only applicable to 'input' fields and not 'select' fields
    validationRules?: FormValidationRule[];
  };
}

type FormValidationRule = {
  regex?: RegExp;
  fn?: (value: string) => boolean;
  errorLabel: string;
};

export function convertPhoneNumberToE164(phoneNumber: string): string {
  return (
    parsePhoneNumberFromString(phoneNumber, "US")?.format("E.164") ??
    phoneNumber
  );
}

function validatePhoneNumber(phoneNumberString: string): string {
  const defaultCountry = "US";
  const isPossiblePhone = isPossiblePhoneNumber(
    phoneNumberString,
    defaultCountry
  );
  const validationError = validatePhoneNumberLength(
    phoneNumberString,
    defaultCountry
  );
  return validationError
    ? validationError
    : !isPossiblePhone
    ? "NOT_A_NUMBER"
    : "";
}

// Returns a URL if the value provided is a valid URL. Returns 'undefined' otherwise.
export const validateWebsite: (value: string) => URL | undefined = (value) => {
  try {
    const website = new URL(value);
    if (website?.protocol === "http:" || website?.protocol === "https:") {
      return website;
    } else {
      return undefined;
    }
  } catch (e) {
    return undefined;
  }
};

export const validateSlug: (value: string) => boolean = (value) => {
  // Taken straight from here in order to not have unnecessary network calls:
  // https://git.soma.salesforce.com/dpae/trailblazer-profile-service/blob/2a6cfa6c380593383d5a7a89acdf07a6eafae65b/functions/authn-userprofile-handler/src/username-validator.ts#L33
  const validationRules = [
    (str: string) => str.length >= 4 && str.length <= 64,
    (str: string) => /[a-z]/.test(str.charAt(0)),
    (str: string) => /[a-z0-9]$/.test(str.charAt(str.length - 1)),
    (str: string) => /^[a-z0-9-]+$/.test(str),
  ];

  return validationRules.every((rule) => {
    return !rule(value) ? false : true;
  });
};

// Returns the text input if it is not blank/empty space. Returns 'undefined' otherwise.
export const validateRequiredTextInput: (
  value: string
) => string | undefined = (value) => {
  return value.replace(/<[^>]*>/g, "").trim().length > 0 ? value : undefined;
};

export const validateSocialHandle: (
  baseUrl: string,
  value: string
) => boolean = (baseUrl, value) => {
  const website = validateWebsite(`${baseUrl}/${value}`);
  if (
    !website ||
    website?.searchParams.toString().length > 0 ||
    website.hash.length > 0 ||
    website.pathname.replace(/^\/(in\/)?/, "").includes("/")
  ) {
    return false;
  }
  return true;
};

// Returns true if the value is within the max limit
export const validateMaximumCharacterLength: (
  value: string,
  maxCharacterLength: number
) => boolean = (value, maxCharacterLength) => {
  return value.length <= maxCharacterLength;
};

// lets browser handle checking if an email is valid
// if browser does not have ability to do so, fall back on
// regex that simply checks for characters, an @, and characters with periods
const validateEmail: (value: string) => boolean = (value) => {
  const emailInput = document.createElement("input");
  let result: boolean;
  emailInput.type = "email";
  emailInput.value = value;

  if (typeof emailInput.checkValidity === "function") {
    result = emailInput.checkValidity();
  } else {
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);
    result = emailRegex.test(value);
  }

  emailInput.remove();

  return result;
};

export const profileEditForm = function (): ProfileEditForm {
  return {
    givenName: {
      validationRules: [
        {
          fn: (value) => !!validateRequiredTextInput(value),
          errorLabel: "profileEditModal.validationRequired",
        },
        {
          fn: (value) => validateMaximumCharacterLength(value, 40),
          errorLabel: "profileEditModal.validationCharacterLength",
        },
      ],
    },
    familyName: {
      validationRules: [
        {
          fn: (value) => !!validateRequiredTextInput(value),
          errorLabel: "profileEditModal.validationRequired",
        },
        {
          fn: (value) => validateMaximumCharacterLength(value, 40),
          errorLabel: "profileEditModal.validationCharacterLength",
        },
      ],
    },
    title: {},
    role: {
      validationRules: [
        {
          fn: (value) => !!validateRequiredTextInput(value),
          errorLabel: "profileEditModal.validationRequired",
        },
      ],
    },
    pronoun: {},
    relationshipToSalesforce: {
      validationRules: [
        {
          fn: (value) => !!validateRequiredTextInput(value),
          errorLabel: "profileEditModal.validationRequired",
        },
      ],
    },
    bio: {},
    slug: {
      validationRules: [
        {
          fn: (value) => validateSlug(value),
          errorLabel: "profileEditModal.validateUsernameError",
        },
      ],
    },
    countryCode: {},
    stateCode: {},
    companyName: {
      validationRules: [
        {
          fn: (value) => !!validateRequiredTextInput(value),
          errorLabel: "profileEditModal.validationRequired",
        },
      ],
    },
    companySize: {},
    companyWebsite: {
      validationRules: [
        {
          fn: (value) => !!validateWebsite(value),
          errorLabel: "profileEditModal.validationWebsite",
        },
      ],
    },
    facebook: {
      validationRules: [
        {
          fn: (value) =>
            !!validateSocialHandle("https://www.facebook.com", value),
          errorLabel: "profileEditModal.validationSocialHandleError",
        },
      ],
    },
    linkedIn: {
      validationRules: [
        {
          fn: (value) =>
            !!validateSocialHandle("https://www.linkedin.com/in", value),
          errorLabel: "profileEditModal.validationSocialHandleError",
        },
      ],
    },
    twitter: {
      validationRules: [
        {
          fn: (value) =>
            !!validateSocialHandle("https://www.twitter.com", value),
          errorLabel: "profileEditModal.validationSocialHandleError",
        },
      ],
    },
    website: {
      validationRules: [
        {
          fn: (value) => !!validateWebsite(value),
          errorLabel: "profileEditModal.validationWebsite",
        },
      ],
    },
    workEmail: {
      validationRules: [
        {
          fn: (value: string) => !!validateEmail(value),
          errorLabel: "profileEditModal.validationEmail",
        },
      ],
    },
    workPhone: {
      validationRules: [
        {
          fn: (value: string) => validatePhoneNumber(value) !== "TOO_SHORT",
          errorLabel: "profileEditModal.validationPhoneTooShort",
        },
        {
          fn: (value: string) => validatePhoneNumber(value) !== "TOO_LONG",
          errorLabel: "profileEditModal.validationPhoneTooLong",
        },
        {
          fn: (value: string) =>
            validatePhoneNumber(value) !== "INVALID_COUNTRY",
          errorLabel: "profileEditModal.validationPhoneInvalidCountry",
        },
        {
          fn: (value: string) => validatePhoneNumber(value) !== "NOT_A_NUMBER",
          errorLabel: "profileEditModal.validationPhoneInvalid",
        },
      ],
    },
    mobilePhone: {
      validationRules: [
        {
          fn: (value: string) => validatePhoneNumber(value) !== "TOO_SHORT",
          errorLabel: "profileEditModal.validationPhoneTooShort",
        },
        {
          fn: (value: string) => validatePhoneNumber(value) !== "TOO_LONG",
          errorLabel: "profileEditModal.validationPhoneTooLong",
        },
        {
          fn: (value: string) =>
            validatePhoneNumber(value) !== "INVALID_COUNTRY",
          errorLabel: "profileEditModal.validationPhoneInvalidCountry",
        },
        {
          fn: (value: string) => validatePhoneNumber(value) !== "NOT_A_NUMBER",
          errorLabel: "profileEditModal.validationPhoneInvalid",
        },
      ],
    },
  };
};
