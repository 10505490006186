import { gql } from "@apollo/client";

export default gql`
  type AppLauncherItem {
    link: Link!
    label: String!
    id: String!
    imageUrl: String!
  }

  # This will eventually move to server, here to represent fields required for the
  # "current user"
  type LegacyUser {
    id: String
    backgroundImageUrl: String!
    fullName: String!
    avatarUrl: String!

    communityUser: PendingCommunityUser
  }

  type SiteUrls {
    baseUrl: String!
    home: String!
    profile: String!
    settings: String!
    assets: String!
    logo: String!
    astroImage: String!
    defaultAstroImage: String!
    mvpImage: String!
    noneMessageSuperbadgeItem: String!
    noneMessageModuleItem: String!
    noneMessageProjectItem: String!
    noneMessageEventItem: String!
    superbadgeImage: String!
    moduleImage: String!
    projectImage: String!
    eventImage: String!
    trailhead: String!
    trailheadUnknownBadge: String!
    trailheadHelpProfileOverview: String!
    trailheadFirstModule: String!
    trailheadFirstModuleImage: String!
    trailheadRanks: String!
    signup: String!
    login: String!
    logout: String!
    defaultSuperbadgeImage: String!
    defaultSuperbadge: String!
    tbcDefaultGroupImage: String!
    tbcSearchFiles: String!
    tbcUserFiles: String!
    tbcCommunityFeed: String!
    tbcUserFeedQuestions: String!
    tbcUserFeedAnswers: String!
    tbcUserFeedBestAnswers: String!
    tbcUserGroups: String!
    tbcUserFeed: String!
    tbcUserConnections: String!
    tbcGroupDetail: String!
    tbcHireMe: String!
    tbcMessageUser: String!
  }

  type SiteInfo {
    name: String!
    logoUrl: String!
    urls: SiteUrls!
  }

  type Link {
    target: String
    href: String!
  }

  type FooterLink {
    label: String!
    href: String!
    image: String
  }

  type Locale {
    label: String!
    value: String!
  }

  type ChassisProfileCompany {
    name: String
    size: String
    website: String
  }

  type ChassisProfileAddress {
    state: String
    country: String
  }

  type ChassisProfilePhone {
    mobile: String
    work: String
  }

  type ChassisProfile {
    id: String!
    firstName: String!
    lastName: String!
    isPublicProfile: Boolean!
    isAvailableForHire: Boolean!
    role: String
    relationshipToSalesforce: String
    username: String
    profileUrl: String
    title: String
    pronoun: String
    nickname: String
    photoUrl: String
    bio: String
    twitterHandle: String
    facebookHandle: String
    linkedinHandle: String
    websiteUrl: String
    company: ChassisProfileCompany
    address: ChassisProfileAddress
    backgroundImageUrl: String
    phone: ChassisProfilePhone
    workEmail: String
  }

  type Chassis {
    profile: ChassisProfile!
    isSelf: Boolean!
  }

  extend interface Profile {
    isSelf: Boolean!
    fullName: String!
  }

  # Extra fields defined here will move to server
  extend type PublicProfile {
    fullName: String!
    tbcUserId: String
    isSelf: Boolean!
    isExternalUser: Boolean!

    communityUser: PendingCommunityUser
    communityStats: PendingCommunityStats
    legacyCommunityConnections: PendingCommunityConnections
  }

  # Extra fields defined here will move to server
  extend type PrivateProfile {
    fullName: String!
    isSelf: Boolean!
  }

  extend type PrivateExternalProfile {
    isSelf: Boolean!
    fullName: String!
  }

  type CommunityAsyncResponse {
    isLoading: Boolean!
    error: String
  }

  type CommunityStats {
    questionsCount: Int!
    answersCount: Int!
    bestAnswersCount: Int!
    communityUserRank: Int!
  }

  type CommunityConnection {
    id: String!
    name: String!
    imageUrl: String!
    url: String!
  }

  type CommunityConnectionCollection {
    count: Int!
    connections: [CommunityConnection!]
    url: String!
  }

  type LegacyCommunityConnections {
    viewerCanFollow: Boolean!
    viewerSubscriptionId: String
    followers: CommunityConnectionCollection!
    groups: CommunityConnectionCollection!
    following: CommunityConnectionCollection!
  }

  type CommunityUser {
    id: String!
    secondaryIds: [String!]
  }

  union PendingCommunityUser = CommunityUser | CommunityAsyncResponse

  union PendingCommunityStats = CommunityStats | CommunityAsyncResponse

  union PendingCommunityConnections =
      LegacyCommunityConnections
    | CommunityAsyncResponse

  extend type Query {
    appLauncherItems(id: ID): [AppLauncherItem!]!
    appLauncherItem(id: ID!): AppLauncherItem
    currentUser: LegacyUser
    profileUser: Profile!
    siteInfo: SiteInfo!
    footerLinks: [FooterLink!]!
    localeOptions: [Locale!]!
    locale: String!
    chassis: Chassis
  }
`;
