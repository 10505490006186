import { gql } from "@apollo/client";

export const PROFILE_EDIT_QUERY = gql`
  query GetProfileEdit {
    chassis @client {
      profile {
        isPublicProfile
        firstName
        lastName
        title
        role
        pronoun
        relationshipToSalesforce
        bio
        username
        address {
          country
          state
        }
        company {
          name
          size
          website
        }
        facebookHandle
        linkedinHandle
        twitterHandle
        websiteUrl
        backgroundImageUrl
        phone {
          mobile
          work
        }
        workEmail
      }
      isSelf
    }

    siteInfo @client {
      urls {
        settings
      }
    }
  }
`;
