import { gql } from "@apollo/client";

export const MVP_QUERY = gql`
  query GetMvpContext {
    chassis @client {
      profile {
        isPublicProfile
        username
      }
      isSelf
    }
    siteInfo @client {
      urls {
        mvpImage
      }
    }
  }
`;

export const TBC_MVP_QUERY = gql`
  query GetTbcMvpContext($userSlug: ID!, $queryMvp: Boolean!) {
    profileData(userSlug: $userSlug) @include(if: $queryMvp) {
      isMvp
    }
  }
`;
