import { api, LightningElement, wire } from "lwc";
import { LabelTranslations, MultiLabelAdapter, t } from "tbme/localization";
import {
  deleteUserImage,
  uploadUserImage,
} from "../../../shared/profileServices";
import { showToast } from "@devforce/th-components/modules/tds/toaster/toaster";
import { QueryAdapter, QueryAdapterValue } from "tbme/queryAdapter";
import { AVATAR_QUERY } from "./query";
import { GetAvatar } from "./gql/GetAvatar";
import { isRenderProfileChassis, chassis } from "tbme/localState";
import { UserProfile } from "../../../shared/profileServices";

export default class extends LightningElement {
  @api isSelf: boolean = false;
  @api profilePhotoUrl?: string;

  defaultImage?: string;
  labels: LabelTranslations = undefined!;
  isExpanded: boolean = false;
  firstName?: string;
  lastName?: string;
  isSaving: boolean = false;

  @wire(MultiLabelAdapter, {
    labels: ["avatar.expandedViewTitle", "avatar.successfulUpload"],
  })
  private handleLabels(labels: { avatar: LabelTranslations }) {
    this.labels = {
      ...labels.avatar,
    };
  }

  @wire(QueryAdapter, {
    query: AVATAR_QUERY,
  })
  private handleResult({
    data,
    loading,
    error,
    errors,
  }: QueryAdapterValue<GetAvatar>) {
    if (!data) {
      return;
    }
    const { chassis, siteInfo } = data;
    if (chassis && siteInfo) {
      const { profile, isSelf } = chassis;
      this.isSelf = isSelf;
      this.defaultImage = data.siteInfo.urls.defaultAstroImage;
      this.profilePhotoUrl =
        data.chassis?.profile.photoUrl ?? this.defaultImage;
      this.firstName = data.chassis?.profile.firstName;
      this.lastName = data.chassis?.profile.lastName;
    }
  }

  private get imageStyle() {
    return `background-image: url('${this.profilePhotoUrl}')`;
  }

  private get photoTitle() {
    if (this.altText) {
      return this.altText;
    }
    return this.profilePhotoUrl
      ? this.profilePhotoUrl.substring(
          this.profilePhotoUrl.lastIndexOf("/") + 1
        )
      : "";
  }

  private get altText() {
    return t("avatar.avatarImageAltText", {
      firstName: this.firstName,
      lastName: this.lastName,
    });
  }

  private closeModal() {
    this.isExpanded = false;
  }

  private expandImage() {
    this.isExpanded = true;
  }

  private handleImageError() {
    if (this.defaultImage) {
      this.profilePhotoUrl = this.defaultImage;
    }
  }

  private async handleSave(event: CustomEvent) {
    try {
      this.isSaving = true;
      const result = await uploadUserImage(
        event.detail,
        `${this.firstName}_${this.lastName}`
      );
      if (typeof result === "string") {
        this.profilePhotoUrl = result;
        this.updateChassisProfile({ photoUrl: result });
        showToast({
          message: t("avatar.successfulUpload"),
          variant: "success",
        });
      } else {
        throw result;
      }
    } catch (e) {
      showToast({
        message: t("avatar.unsuccessfulUpload"),
        variant: "error",
      });
    } finally {
      this.isSaving = false;
    }
  }

  async handleRemove() {
    try {
      this.isSaving = true;
      const result = await deleteUserImage();
      this.profilePhotoUrl = result.photoUrl;
      this.updateChassisProfile({ photoUrl: result.photoUrl });
      showToast({
        message: t("avatar.successfulDeletion"),
        variant: "success",
      });
    } catch (e) {
      showToast({
        message: t("avatar.unsuccessfulUpload"),
        variant: "error",
      });
    } finally {
      this.isSaving = false;
    }
  }

  updateChassisProfile(value: Partial<UserProfile>) {
    if (!isRenderProfileChassis()) {
      return;
    }

    const profile = Object.assign({}, chassis()!.profile, value) as UserProfile;

    chassis({
      ...chassis()!,
      profile,
    });
  }
}
