import { gql } from "@apollo/client";

export const USER_CONTEXT_QUERY = gql`
  query GetUserContext {
    chassis @client {
      profile {
        isPublicProfile
        username
      }
      isSelf
    }
  }
`;

export const TBC_USER_FILES_QUERY = gql`
  query GetFilesUrl($userSlug: ID!, $queryFiles: Boolean!) {
    profileData(userSlug: $userSlug) @include(if: $queryFiles) {
      communityUserUrls {
        filesUrl
      }
    }
  }
`;
